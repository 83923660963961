/* COMMON STYLES */
:root {
	/* Colors: */
	--unnamed-color-ff6d6d: #ff6d6d;
	--unnamed-color-87c1ff: #87c1ff;
	--unnamed-color-007bff: #007bff;
	--unnamed-color-2d42a8: #2d42a8;
	--unnamed-color-00568f: #00568f;
	--unnamed-color-ffffff: #ffffff;
	--unnamed-color-eeeeee: #eeeeee;
	--unnamed-color-4d4d4d: #4d4d4d;
	--unnamed-color-25274d: #25274d;

	/* Font/text values */
	--unnamed-font-family-ibm-plex-sans: IBM Plex Sans;
	--unnamed-font-family-open-sans: Open Sans;
	--unnamed-font-family-poppins: Poppins;
	--unnamed-font-style-normal: normal;
	--unnamed-font-weight-600: 600px;
	--unnamed-font-weight-normal: normal;
	--unnamed-font-size-12: 12px;
	--unnamed-font-size-14: 14px;
	--unnamed-font-size-15: 15px;
	--unnamed-font-size-16: 16px;
	--unnamed-font-size-18: 18px;
	--unnamed-font-size-22: 22px;
	--unnamed-font-size-28: 28px;
	--unnamed-font-size-44: 44px;
	--unnamed-font-size-62: 62px;
	--unnamed-character-spacing-0: 0px;
	--unnamed-character-spacing-1-4: 1.4px;
	--unnamed-character-spacing-1-2: 1.2px;
	--unnamed-line-spacing-18: 18px;
	--unnamed-line-spacing-23: 23px;
	--unnamed-line-spacing-24: 24px;
	--unnamed-line-spacing-30: 30px;
	--unnamed-line-spacing-32: 32px;
	--unnamed-line-spacing-36: 36px;
	--unnamed-line-spacing-56: 56px;
	--unnamed-line-spacing-66: 66px;
	--unnamed-text-transform-uppercase: uppercase;
}

/* Character Styles */
.h1 {
	font-family: var(--unnamed-font-family-ibm-plex-sans);
	font-style: var(--unnamed-font-style-normal);
	font-weight: var(--unnamed-font-weight-normal);
	font-size: var(--unnamed-font-size-62);
	line-height: var(--unnamed-line-spacing-66);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-25274d);
}
.subtitle {
	font: normal normal 600 16px/24px IBM Plex Sans;
	letter-spacing: 1.2px;
	color: #25274D;
	text-transform: uppercase;
}
.h2 {
	font: normal normal 600 44px/56px IBM Plex Sans;
	line-height: var(--unnamed-line-spacing-56);
	text-align: center;
	letter-spacing: 0px;
	color: #25274D;

}
.h3 {
	text-align: left;
	font: normal normal 600 28px/36px IBM Plex Sans;
	letter-spacing: 0px;
	color: #25274D;
}

.iconFontAwesome {
    color: var(--unnamed-color-007bff);
}

.bloq {
	font-family: var(--unnamed-font-family-ibm-plex-sans);
	font-style: var(--unnamed-font-style-normal);
	font-weight: var(--unnamed-font-weight-600);
	font-size: var(--unnamed-font-size-22);
	line-height: var(--unnamed-line-spacing-30);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-007bff);
}
.link {
	font-family: var(--unnamed-font-family-open-sans);
	font-style: var(--unnamed-font-style-normal);
	font-weight: var(--unnamed-font-weight-normal);
	font-size: var(--unnamed-font-size-18);
	line-height: var(--unnamed-line-spacing-32);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-007bff);
}
.body, .grey {
	font: normal normal normal 18px/32px Open Sans;
	line-height: var(--unnamed-line-spacing-32);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-4d4d4d);
}
.smallnote {
	font-family: var(--unnamed-font-family-open-sans);
	font-style: var(--unnamed-font-style-normal);
	font-weight: var(--unnamed-font-weight-normal);
	font-size: var(--unnamed-font-size-12);
	line-height: var(--unnamed-line-spacing-18);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-4d4d4d);
}
@media (max-width: 992px) {
	.smallnote {
		text-align: center;
	}
	.subtitle {
		text-align: center;
	}
}
.label {
	text-align: center;
	font: normal normal 600 14px/18px IBM Plex Sans;
	letter-spacing: 1.4px;
	color: #25274D;
	text-transform: var(--unnamed-text-transform-uppercase);
}
.btns {
	font-family: var(--unnamed-font-family-poppins);
	font-style: var(--unnamed-font-style-normal);
	font-weight: var(--unnamed-font-weight-600);
	font-size: var(--unnamed-font-size-15);
	line-height: var(--unnamed-line-spacing-23);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-ffffff);
}

.swal2-container {
	z-index: 10000;
}

.container{
	background: transparent 0 0 no-repeat padding-box;
}



.ico-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
/* COMMONS */
@media (min-width: 1600px) {
	.container  {
    max-width: 1500px;
	}
}

.separator {
    width: 100%;
    text-align: center;
    padding: 30px 0;
    box-shadow: 0 1px 2px -1px rgb(0 0 0 / 25%);
}

.bg-linear-gradient {
    background: transparent linear-gradient(217deg, #2D42A8 0%, #25274D 100%) 0% 0% no-repeat padding-box;
	background-size: cover;
  	opacity: 1;
}

/* HEADER COMPONENT STYLES */
.header-nav-link {
	font: normal normal normal 16px/24px Roboto;
	color: var(--unnamed-color-ffffff) !important;
	letter-spacing: 0.32px;
	padding: 0;
}
.header-nav-link:hover {
	color: #007BFF !important;
}

.header-dropdown-text {
	font: normal normal 600 14px/18px IBM Plex Sans;
	letter-spacing: var(--unnamed-character-spacing-1-4);
	color: var(--unnamed-color-ffffff);
	text-align: left;
	text-transform: uppercase;
	opacity: 1;
	text-decoration: none;
}

/* Buttons */
.btn {
	height: 54px;
	padding-left: 1em;
	padding-right: 1em;
	padding: 12px;
}

.btn-ghost {
	border-radius: 27px;
	border: 2px solid #FFFFFF;
	font: normal normal 600 15px/23px Poppins;
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--unnamed-color-ffffff);
	background-color: transparent;
	text-align: center;
	letter-spacing: 0px;
	opacity: 1;
	padding: 1em;
	padding-left: 2em;
	padding-right: 2em;
}

.btn-ghost:hover{
	color: #007BFF;
	border-color: #007BFF;
}

.btn-orange {
	background: #FF6D6D 0% 0% no-repeat padding-box;
	border-radius: 27px;
	border-color: transparent;
	text-align: center;
	font: normal normal 600 15px/23px Poppins;
	letter-spacing: 0px;
	color: #FFFFFF;
	opacity: 1;
	border: none;
	transition: all .25s ease-in-out;
}
.btn-orange:hover {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	color: #FF6D6D;
}

.btn-blue {
	background: #25274D 0% 0% no-repeat padding-box;
	border-radius: 27px;
	border-color: transparent;
	text-align: center;
	font: normal normal 600 15px/23px Poppins;
	letter-spacing: 0px;
	color: #FFFFFF;
	opacity: 1;
	border: none;
	transition: all .25s ease-in-out;
}
.btn-blue:hover {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	color: #25274D;
	border: 1px solid  #25274D;
}

.btn-light {
	background: #FF6D6D 0% 0% no-repeat padding-box;
	border-radius: 27px;
	text-align: center;
	font: normal normal 600 15px/23px Poppins;
	letter-spacing: 0px;
	color: #FFFFFF;
	border-radius: 27px;
	transition: all .25s ease-in-out;
}
.btn-light:hover {
	background: #FFFFFF 0% 0% no-repeat padding-box !important;
	color: #FF6D6D;
}
.btn-with-border:hover {
	background: #FFFFFF 0% 0% no-repeat padding-box !important;
	color: #FF6D6D;
	border: 1px solid #FF6D6D;
}
.btn-secondary {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 2px solid #00568F;
	border-radius: 27px;
	text-align: center;
	font: normal normal 600 15px/23px Poppins;
	letter-spacing: 0px;
	color: #00568F;
	border-radius: 27px;
	transition: all .25s ease-in-out;
}
.btn-secondary:hover {
	background-color: #FFFFFF;
	color: #007BFF;
	border-color: #007BFF;
}
.btn-secondary:visited {
	background-color: #FFFFFF;
	color: #007BFF;
}



.disabled-element {
	opacity: .4;
	pointer-events: none;
}

.important{
	text-align: left;
    font: normal normal 600 16px/24px;
    letter-spacing: 1.2px;
    color: #25274d;
    opacity: 1;
    margin-bottom: 32px;
}


.ico-blueline, .ico-greyline {
    width: 100%;
    margin-top: 11px;
    margin-bottom: 11px;
}

.ico-blueline {
    border: 1px solid #007bff;
}

.ico-greyline {
    border: 1px solid #f6f6f6;
}

.list li{
	padding: 6px 0;
	font: normal normal 600 20px/36px IBM Plex Sans;
	line-height: 18px;
	display: inline-block;
	width: 100%;
	color:#25274d;
}

.list a{
	text-decoration: none;
	color:#25274d;
}

.title-h3 {
    margin-bottom: 20px;
	margin-top: 40px;
}

.icon-h3{
	text-align: left;
	font: normal normal 600 28px/36px IBM Plex Sans;
	letter-spacing: 0px;
	color: #25274D;
	margin-left: 8px;
}

