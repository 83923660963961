.bit_bcn_footer {
    background: #25274d 0% 0% no-repeat padding-box;
    opacity: 1;
  }
  
  .bit_bcn_footer .private-sale {
    text-align: left;
    font: normal normal 600 44px/56px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    word-break: break-word;
  }
  
  .bit_bcn_footer .private-sale-caption {
    text-align: left;
    font: normal normal 600 16px/24px IBM Plex Sans;
    letter-spacing: 1.2px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
  }
  
  .ctd-sec {
    text-align: left;
    font: normal normal 600 16px/24px IBM Plex Sans;
    letter-spacing: 1.2px;
    color: #ffffff;
    text-decoration: underline;
    text-transform: uppercase;
    opacity: 1;
  }
  
  .bit_bcn_footer button.btn-orange {
    min-width: 225px;
    height: 54px;
    margin-right: 15px;
  }
  
  .bit_bcn_footer button.btn-ghost {
    min-width: 225px;
    height: 54px;
    padding-right: 0;
    padding-left: 0;
  }
  
  .bit_bcn_footer .countdown-wrapper {
    margin-top: 26px;
  }
  
  .bit_bcn_footer .countdown-number {
    text-align: left;
    font: normal normal normal 62px/66px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
  
  .bit_bcn_footer .countdown-caption {
    text-align: center;
    font: normal normal normal 13px/20px Poppins;
    letter-spacing: 0.65px;
    color: #ffffff;
    opacity: 1;
  }
  
  .bit_bcn_footer a.footer-link {
    text-align: left;
    font: normal normal normal 12px/15px IBM Plex Sans;
    letter-spacing: 1.2px;
    color: #87c1ff;
    text-transform: uppercase;
    opacity: 1;
    text-decoration: none;
  }
  .bit_bcn_footer a.footer-link:hover {
    color: #fff;
  }
  .bit_bcn_footer .footer-links-wrapper {
    padding-bottom: 53px;
    padding-top: 99px;
  }
  
  .footer-links-wrapper > * {
    margin: 0px 15px 30px 30px;
  }
  
  .bit_bcn_footer .footer-content-wrapper {
    padding-top: 130px;
  }
  
  @media (min-width: 767px) {
    .bit_bcn_footer .countdown-item {
      margin-right: 53px;
    }
  }
  
  @media (min-width: 767px) {
    .bit_bcn_footer .footer-logo {
      margin-right: 30px;
      margin-bottom: 0px;
    }
  }
  
  @media (max-width: 767px) {
    .bit_bcn_footer .footer-logo,
    .bit_bcn_footer .footer-title {
      align-self: center;
    }
  }
  
  .bit_bcn_footer .footer-logo {
    margin-bottom: 30px;
  }
  