

.ico-sale-right-col{
	display: flex;
    flex-direction: column;
    background: #fff 0 0 no-repeat padding-box;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    border-radius: 10px;
	padding: 25px 15px 4px;
    padding: 10px 10px 10px 10px;
    width: 100%;
	margin: 15px auto;
    max-width: 344px;
}

.metamask-header {
	width: 150px;
    margin-left: auto;
  	margin-right: auto;
  	display: block;
	margin-bottom: 4px;
}

.ico-description {
    margin-top: 40px;
	margin-right: 40px;
    text-align: justify;
	max-width: 1000px;
}

.token-fund-goal {
	padding: 0.5em;
 	align-items: center;
  	justify-content: center;
}

.token-fund-goal-money{
	text-align: center;
    font-size: 22px;
    font-weight: 700;
	color: var(--unnamed-color-007bff);
}

.token-fund-goal-total{
	text-align: center;
	font-weight: 700;
    font-size: 16px;
    color: rgba(0,0,0,.55);
}

.ico-sale-amount{
    border-color: #DAD6D6;
    border-width: 1px 1px 1px 1px;
    border-radius: 10px 10px 10px 10px;
    color: #3F3F3F;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    padding: 15px 25px 15px 25px;
}


.ico-sale-amount:focus {
    box-shadow: inset 0 0 0 1px rgb(0 0 0 / 10%);
    outline: 0
}


.buy-btn {
	background: #ff6d6d 0% 0% no-repeat padding-box;
	border-radius: 27px;
	opacity: 1;
	height: 54px;
	border: none;
	text-align: center;
	font: normal normal 600 15px/23px Poppins;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
	transition: all 0.25s ease-in-out;
    display: flex;
    justify-content: center;
    margin: 1em;
    align-items: center;
    display: flex
  }



.buy-btn:hover {
	background: #ffffff 0% 0% no-repeat padding-box;
	color: #ff6d6d;
	border: 1px solid #ff6d6d;
}