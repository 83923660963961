#middle-desk {
    margin-top: 20px;
    margin-bottom: 5px;
}

.ico-media {
    display: flex;
    flex-direction: column;
    width: 75%;
    padding-top: 10px;
}

.ico-logo {
    width: 60px !important;
    height: 60px !important;
    border-radius: 30px !important;
    overflow: hidden;
    margin-right: 12px;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    vertical-align: top;
}

.ico-main-info-title {
    text-align: left;
    font: normal normal 600 62px/66px IBM Plex Sans;
    letter-spacing: 0;
    opacity: 1;
    margin-bottom: 9px;
    word-break: break-word;
}

.ico-main-info {
    display: flex;
    flex-direction: column;
    width: 75%;
    padding-top: 15px;
}

.embed-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 1000px;
}

.ico-right-col {
    display: flex;
    flex-direction: column;
    background: #fff 0 0 no-repeat padding-box;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    border-radius: 10px;
    padding: 25px 15px 4px;
    padding: 10px 10px 10px 10px;
    width: 100%;
    margin: 15px auto;
    max-width: 344px;
}

.token-sale-ends {
    text-align: center;
    font: normal normal 600 14px/18px IBM Plex Sans;
    letter-spacing: 1.4px;
    color: #25274d;
    text-transform: uppercase;
    text-transform: var(--unnamed-text-transform-uppercase);
}

.token-sale-ends-days {
    text-align: center!important;
}

.token-fund-goal-money {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    color: var(--unnamed-color-007bff);
}

.token-fund-goal-total {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    color: rgba(0,0,0,.55);
}

.token-button {
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 1em;
    border: none;
}

.social-links-title {
    text-align: center;
    font: normal normal 600 14px/18px IBM Plex Sans;
    letter-spacing: 1.4px;
    color: #25274d;
    text-transform: uppercase;
    text-transform: var(--unnamed-text-transform-uppercase);
}

.social-links {
    display: flex;
    padding: 0.5em;
    align-items: center;
    justify-content: center;
}

.social-links > a {
    margin: 0.25em 0.5em 0.25em 0.5em;
    align-items: center;
}

