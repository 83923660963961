.bit_bcn_header {
  min-height: 150px;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bit_bcn_header a.navbar-brand {
  padding: 0;
}

.bit_bcn_header .bit_bcn_nav {
  flex: 1;
}

.bit_bcn_header button.btn-ghost {
  min-width: 150px;
  min-height: 54px;
}

@media (max-width: 992px) {
  .bit_bcn_header {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .bit_bcn_header div.dropdown {
    margin-left: 35px;
  }
}

@media (min-width: 1600px) {
  .bit_bcn_header a.navbar-brand {
    margin-right: 100px;
  }
  .bit_bcn_header div.dropdown {
    margin-left: 57px;
  }
  .bit_bcn_header li.nav-item {
    margin-right: 35px;
  }
}