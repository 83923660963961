.bit_bcn_social {
    background: transparent linear-gradient(101deg, #2D42A8 0%, #25274D 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-top: 40px;
}

.social-row {
    padding-top: 97px;
    padding-bottom: 97px
}

.social-wrapper {
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.social-name {
    text-align: center;
    font: normal normal 600 16px/24px IBM Plex Sans;
    letter-spacing: 1.2px;
    color: #87C1FF;
    text-transform: uppercase;
    opacity: 1;
    margin: 0;
    text-decoration: none;
}

.social-img {
    height: 128px;
    width: 128px;
    margin-bottom: 22px;
    margin-right: 48px;
    margin-left: 48px;
}


.follow-us {
	text-align: left;
	font: normal normal 600 28px/36px IBM Plex Sans;
	letter-spacing: 0px;
	color: #FFFFFF;
	opacity: 1;
	margin-bottom: 6px;
}


.follow-us > a {
    text-align: left;
    font: normal normal 600 28px/36px IBM Plex Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 6px;
    text-decoration: none;
}

.follow-us > a:hover{
    color: #87C1FF; 
}

.community {
    text-align: left;
    font: normal normal 600 16px/24px IBM Plex Sans;
    letter-spacing: 1.2px;
    color: #87C1FF;
    text-transform: uppercase;
    opacity: 1;
}

.bit_bcn_social .ico-telegram {
    padding-right: 15px;
}
